module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","it"],"defaultLanguage":"it","generateDefaultLanguagePage":false,"redirect":false,"siteUrl":"https://astrorei.io","i18nextOptions":{"deafultNS":"common","interpolation":{"escapeValue":false},"nsSeparator":":"},"pages":[{"matchPath":"/:lang?/blog/:slug?","getLanguageFromPath":true,"languages":["it"]},{"matchPath":"/:lang?/servizi/:slug","getLanguageFromPath":true,"languages":["it"]},{"matchPath":"/:lang/services/:slug","getLanguageFromPath":true,"languages":["en"]},{"matchPath":"/:lang?/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/privacy/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PFGNP7J","gtmAuth":"5FAfH9G4sBkiitXwd4TNQg","gtmPreview":"env-4","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Astrorei","short_name":"Astrorei","start_url":"/","background_color":"#ffffff","theme_color":"#EB611C","display":"minimal-ui","icon":"src/assets/images/logo_simple.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"14d2e398b35b2f1e5551ad4e083d403f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
